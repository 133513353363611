// Store
import store from "@/store";

// Models
import {AppIncomingInvoiceItemIndex} from "@/models/app/incoming-invoice-item";

// Services
import {getYear} from "@/services/app/date";

export interface AppIncomingInvoiceCreateIndex {
  organization_id: string | string[];
  partner_id: string | string[];
  invoice_type_id: string | string[];
  content: {
    year: string;
    invoiceNumber: string;
    invoiceNumberInYear: string;
    accountNumber: string;
    paymentDescription: string;
    model: string;
    referenceNumber: string;
    listOfItemsStatus: string;
    amount: {
      hrk: string,
      eur: string,
    };
    paidAmount: {
      hrk: string,
      eur: string,
    };
    status: string;
    date: string;
    dateOfExchangeRate: string;
    dateOfClosing: string;
  };
  incoming_invoice_items: Array<AppIncomingInvoiceItemIndex>;
}

export class AppIncomingInvoiceCreateIndex implements AppIncomingInvoiceCreateIndex {
  organization_id: string | string[];
  partner_id: string | string[];
  invoice_type_id: string | string[];
  content: {
    year: string;
    invoiceNumber: string;
    invoiceNumberInYear: string;
    accountNumber: string;
    paymentDescription: string;
    model: string;
    referenceNumber: string;
    listOfItemsStatus: string;
    amount: {
      hrk: string,
      eur: string,
    };
    paidAmount: {
      hrk: string,
      eur: string,
    };
    status: string;
    date: string;
    dateOfExchangeRate: string;
    dateOfClosing: string;
  };
  incoming_invoice_items: Array<AppIncomingInvoiceItemIndex>;

  constructor() {
    this.organization_id = store.getters.getOrganizationMember.organization_id ?? "";
    this.partner_id = "";
    this.invoice_type_id = "";
    this.content = {
      year: getYear(),
      invoiceNumber: "",
      invoiceNumberInYear: "",
      accountNumber: "",
      paymentDescription: "",
      model: "",
      referenceNumber: "",
      listOfItemsStatus: "0",
      amount: {
        hrk: "",
        eur: "",
      },
      paidAmount: {
        hrk: "0",
        eur: "0",
      },
      status: "0",
      date: "",
      dateOfExchangeRate: "",
      dateOfClosing: "",
    };
    this.incoming_invoice_items = [];
  }
}