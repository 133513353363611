
// Vue
import {Options, Vue} from "vue-class-component";

// Models
import {AppPartnerIndex} from "@/models/app/partner";
import {AppPartnerAccountIndex} from "@/models/app/partner-account";
import {AppIncomingInvoiceIndex} from "@/models/app/incoming-invoice";
import {ResponseIndex} from "@/models/response";
import {PaginationIndex} from "@/models/pagination";

// Components
import {
  ElDialog,
  ElRow,
  ElCol,
  ElTable,
  ElTableColumn,
  ElForm,
  ElFormItem,
  ElInput,
  ElDatePicker,
  ElSelect,
  ElOption,
  ElButton,
} from "element-plus";

// Services
import {putRequest} from "@/services/api/request";
import {getTranslation} from "@/services/app/translation";

@Options({
  props: [
    "incomingInvoice",
    "partners",
    "articles",
    "placeOfCosts",
    "invoiceTypes",
    "searchQuery",
    "partnerId",
    "status",
    "year",
    "pagination",
  ],
  components: {
    ElDialog,
    ElRow,
    ElCol,
    ElTable,
    ElTableColumn,
    ElForm,
    ElFormItem,
    ElInput,
    ElDatePicker,
    ElSelect,
    ElOption,
    ElButton,
  },
  watch: {
    'formData.partner_id'(): void {
      this.partner = this.partners.find((item) => item.id === this.formData.partner_id);
      if (this.partner) {
        if (this.isInitialLoad) {
          this.partnerAccounts = this.partner.partner_accounts;
          this.isInitialLoad = false;
        } else {
          this.partnerAccounts = this.partner.partner_accounts;
          this.formData.content.accountNumber = "";
        }
      } else {
        this.formData.content.accountNumber = "";
      }
    },
    'formData.content.amount.eur'(): void {
      this.formData.content.amount.hrk = Number(this.formData.content.amount.eur * 7.53450).toFixed(2);
    },
    "formData.content.paidAmount.eur"(): void {
      this.formData.content.paidAmount.hrk = Number((this.formData.content.paidAmount.eur) * 7.53450).toFixed(2);
    },
  },
})
export default class AppAdministratorInvoicesIncomingInvoicesListFormUpdateIndexVue extends Vue {
  isLoading = false;
  isDialogVisible = false;
  isInitialLoad = true;

  partner: AppPartnerIndex = new AppPartnerIndex();
  partnerAccounts: Array<AppPartnerAccountIndex> = [];
  incomingInvoice: AppIncomingInvoiceIndex = new AppIncomingInvoiceIndex();

  searchQuery = "*";
  partnerId = "*";
  status = "*";
  year = new Date().getFullYear();
  pagination = new PaginationIndex();

  formData: AppIncomingInvoiceIndex = new AppIncomingInvoiceIndex();
  formDataRules = {
    partner_id: [
      {
        required: true,
        trigger: "blur",
      },
    ],
    invoice_type_id: [
      {
        required: true,
        trigger: "blur",
      },
    ],
    content: {
      year: [
        {
          required: true,
          trigger: "blur",
        },
      ],
      invoiceNumber: [
        {
          required: true,
          trigger: "blur",
        },
      ],
      invoiceNumberInYear: [
        {
          required: true,
          trigger: "blur",
        },
      ],
      paymentDescription: [
        {
          required: true,
          trigger: "blur",
        },
      ],
      amount: {
        hrk: [
          {
            required: true,
            trigger: "blur",
          },
        ],
        eur: [
          {
            required: true,
            trigger: "blur",
          },
        ],
      },
      date: [
        {
          required: true,
          trigger: "blur",
        },
      ],
      dateOfExchangeRate: [
        {
          required: true,
          trigger: "blur",
        },
      ],
    },
  };

  get translation(): any {
    return getTranslation([
      "accountNumber",
      "add",
      "amountWithVAT",
      "amountWithoutVAT",
      "article",
      "choose",
      "closed",
      "date",
      "dateOfExchangeRate",
      "edit",
      "listOfItemsStatus",
      "invoiceNumber",
      "invoiceNumberInYear",
      "invoiceType",
      "opened",
      "partner",
      "paymentDescription",
      "placeOfCost",
      "save",
      "status",
      "titlePlaceholderAmountWithVAT",
      "titlePlaceholderAmountWithoutVAT",
      "titlePlaceholderDate",
      "titlePlaceholderDateOfExchangeRate",
      "titlePlaceholderInvoiceNumber",
      "titlePlaceholderInvoiceNumberInYear",
      "titlePlaceholderPaymentDescription",
      "titlePlaceholderUnitAmount",
      "titlePlaceholderUnitDiscount",
      "titlePlaceholderUnitPrice",
      "titlePlaceholderYear",
      "unitAmount",
      "unitDiscount",
      "unitPrice",
      "year",
    ]);
  }

  get isIncomingInvoiceClosed(): boolean {
    return this.incomingInvoice.content.status === "1";
  }

  get isValidIncomingInvoiceItems(): boolean {
    let status = true;

    if (this.formData.content.listOfItemsStatus === "1") {
      if (this.formData.incoming_invoice_items.length === 0) {
        status = false;
      }

      this.formData.incoming_invoice_items.forEach(item => {
        if (item.article_id === "") {
          status = false;
        }

        if (item.place_of_cost_id === "") {
          status = false;
        }

        if (item.content.amount.eur === "") {
          status = false;
        }

        if (item.content.unitPrice.eur === "") {
          status = false;
        }

        if (item.content.unitAmount === "") {
          status = false;
        }

        if (item.content.unitDiscount === "") {
          status = false;
        }
      });
    }

    return status;
  }

  $refs!: {
    formComponent: HTMLFormElement;
  };

  validateForm(): void {
    this.$refs.formComponent.validate((response: any) => {
      if (response && this.isValidIncomingInvoiceItems) {
        this.submitForm();
      }
    });
  }

  async submitForm(): Promise<void> {
    this.isLoading = true;
    await putRequest({
      uri: "/common/incoming-invoice/update",
      formData: this.formData,
      isProtected: true,
      isSuccessNotificationVisible: true,
      isErrorNotificationVisible: true,
    }).then((r: ResponseIndex) => {
      if (r.status === "success") {
        console.log(this.searchQuery, this.partnerId, this.status, this.year, this.pagination.current_page);
        this.$emit('getIncomingInvoices', this.searchQuery, this.partnerId, this.status, this.year, this.pagination.current_page);
        this.isDialogVisible = false;
        this.resetFormData();
      }
    });
    this.isLoading = false;
  }

  setFormData(): void {
    this.formData = this.incomingInvoice;
  }

  resetFormData(): void {
    this.formData = new AppIncomingInvoiceIndex();
  }

  updated(): void {
    this.setFormData();
  }
}
