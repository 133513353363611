
// Vue
import {Options, Vue} from "vue-class-component";

// Components
import {
  ElForm,
  ElFormItem,
  ElInput,
  ElOption,
  ElSelect,
} from "element-plus";

// Services
import {getTranslation} from "@/services/app/translation";

@Options({
  props: [
    "partners",
  ],
  components: {
    ElForm,
    ElFormItem,
    ElInput,
    ElOption,
    ElSelect,
  },
})
export default class AppAdministratorInvoicesIncomingInvoicesListFormFilterIndexVue extends Vue {
  isLoading = false;

  formData = {
    searchQuery: "",
    partner_id: "*",
    status: "*",
    year: new Date().getFullYear(),
  };

  years: Array<number> = this.getYears();

  get translation(): any {
    return getTranslation([
      "allPartners",
      "allStatuses",
      "choose",
      "closed",
      "filter",
      "status",
      "opened",
      "search",
      "year",
    ]);
  }

  $refs!: {
    formComponent: HTMLFormElement;
  };

  async submitForm(): Promise<void> {
    this.$emit("getIncomingInvoices", this.formData.searchQuery.length ? this.formData.searchQuery : "*", this.formData.partner_id, this.formData.status, this.formData.year);
  }

  getYears(): Array<number> {
    const years = [];

    for (let i = new Date().getFullYear(); i >= 2006; i--) {
      years.push(i);
    }

    return years;
  }

  async setSearch(): Promise<void> {
    if (this.formData.searchQuery.length > 0 || this.formData.searchQuery.length === 0) {
      await this.submitForm();
    }
  }
}
