
// Vue
import {Options, Vue} from "vue-class-component";

// Store
import store from "@/store/index";

// Models
import {AppIncomingInvoiceIndex} from "@/models/app/incoming-invoice";
import {AppPartnerIndex} from "@/models/app/partner";
import {AppArticleIndex} from "@/models/app/article";
import {AppPlaceOfCostIndex} from "@/models/app/place-of-cost";
import {AppInvoiceTypeIndex} from "@/models/app/invoice-type";
import {AppTransactionIndex} from "@/models/app/transaction";
import {ResponseIndex} from "@/models/response";
import {PaginationIndex} from "@/models/pagination";

// Components
import {
  ElTable,
  ElTableColumn,
  ElButton,
  ElPagination,
} from "element-plus";
import {Search, Edit, Delete} from "@element-plus/icons-vue";
import AppAdministratorInvoicesIncomingInvoicesListFormFilterIndexVue from "@/views/app/administrator/invoices/incoming-invoices/list/form/filter.vue";
import AppAdministratorInvoicesIncomingInvoicesListFormLookupIndexVue from "@/views/app/administrator/invoices/incoming-invoices/list/form/lookup.vue";
import AppAdministratorInvoicesIncomingInvoicesListFormCreateIndexVue from "@/views/app/administrator/invoices/incoming-invoices/list/form/create.vue";
import AppAdministratorInvoicesIncomingInvoicesListFormUpdateIndexVue from "@/views/app/administrator/invoices/incoming-invoices/list/form/update.vue";
import AppAdministratorInvoicesIncomingInvoicesListFormRemoveIndexVue from "@/views/app/administrator/invoices/incoming-invoices/list/form/remove.vue";
import AppAdministratorInvoicesIncomingInvoicesListFormCreateIncomingInvoicePaymentIndexVue from "@/views/app/administrator/invoices/incoming-invoices/list/form/create-incoming-invoice-payment.vue";
import LoadingIndexVue from "@/components/loading.vue";

// Services
import {getRequest} from "@/services/api/request";
import {getDateFormat} from "@/services/app/date";
import {getInformation, getCurrencyFormatHRK, getCurrencyFormatEUR, getInvoiceStatusShort, getInvoiceStatusClass, getInvoiceNumberInYear} from "@/services/app/data";
import {getTranslation} from "@/services/app/translation";

@Options({
  components: {
    ElTable,
    ElTableColumn,
    ElButton,
    ElPagination,
    Search,
    Edit,
    Delete,
    AppAdministratorInvoicesIncomingInvoicesListFormFilterIndexVue,
    AppAdministratorInvoicesIncomingInvoicesListFormLookupIndexVue,
    AppAdministratorInvoicesIncomingInvoicesListFormCreateIndexVue,
    AppAdministratorInvoicesIncomingInvoicesListFormUpdateIndexVue,
    AppAdministratorInvoicesIncomingInvoicesListFormRemoveIndexVue,
    AppAdministratorInvoicesIncomingInvoicesListFormCreateIncomingInvoicePaymentIndexVue,
    LoadingIndexVue,
  },
})
export default class AppAdministratorInvoicesIncomingInvoicesListIndexVue extends Vue {
  isLoading = false;

  searchQuery = "*";
  partner_id = "*";
  status = "*";
  year = new Date().getFullYear();
  pagination: PaginationIndex = new PaginationIndex();

  incomingInvoice: AppIncomingInvoiceIndex = new AppIncomingInvoiceIndex();
  incomingInvoices: Array<AppIncomingInvoiceIndex> = [];
  partners: Array<AppPartnerIndex> = [];
  articles: Array<AppArticleIndex> = [];
  placeOfCosts: Array<AppPlaceOfCostIndex> = [];
  invoiceTypes: Array<AppInvoiceTypeIndex> = [];
  transactions: Array<AppTransactionIndex> = [];

  $refs!: {
    AppAdministratorInvoicesIncomingInvoicesListFormLookupIndexVue: any;
    AppAdministratorInvoicesIncomingInvoicesListFormUpdateIndexVue: any;
    AppAdministratorInvoicesIncomingInvoicesListFormRemoveIndexVue: any;
    AppAdministratorInvoicesIncomingInvoicesListFormCreateIncomingInvoicePaymentIndexVue: any;
  };

  get isDataLoaded(): boolean {
    return this.incomingInvoices.length > 0 && this.isLoading === false;
  }

  get isDataLoadedAndEmpty(): boolean {
    return this.incomingInvoices.length === 0 && this.isLoading === false;
  }

  get isPaginationVisible(): boolean {
    return this.pagination.last_page > 1 && this.isLoading === false;
  }

  getDateFormat(data: string): string {
    return getDateFormat(data);
  }

  getInformation(data: string): string {
    return getInformation(data);
  }

  getCurrencyFormatHRK(data: string): string {
    return getCurrencyFormatHRK(data);
  }

  getCurrencyFormatEUR(data: string): string {
    return getCurrencyFormatEUR(data);
  }

  getInvoiceStatusShort(data: string): string {
    return getInvoiceStatusShort(data);
  }

  getInvoiceStatusClass(data: string): string {
    return getInvoiceStatusClass(data);
  }

  getInvoiceNumberInYear(number: string, year: string): string {
    return getInvoiceNumberInYear(number, year);
  }

  get translation(): any {
    return getTranslation([
      "amountWithVAT",
      "date",
      "dateOfExchangeRateShort",
      "edit",
      "incomingInvoices",
      "invoiceNumber",
      "invoiceNumberInYear",
      "invoiceNumberInYearURA",
      "noData",
      "paid",
      "partner",
      "paymentDescription",
      "payments",
      "remove",
      "review",
      "status",
    ]);
  }

  async getIncomingInvoices(searchQuery = "*", partner_id = "*", status = "*", year = new Date().getFullYear(), page = 1): Promise<void> {
    this.isLoading = true;
    await getRequest({
      uri: `/common/incoming-invoices`,
      formData: {
        organization_id: store.getters.getOrganizationMember.organization_id,
        searchQuery: this.searchQuery = searchQuery,
        partner_id: this.partner_id = partner_id,
        status: this.status = status,
        year: this.year = year,
        page: this.pagination.current_page = page,
        pagination: true,
      },
      isProtected: true,
    }).then((r: ResponseIndex) => {
      if (r.data) {
        this.incomingInvoices = r.data.data;
        this.pagination = {
          current_page: r.data.current_page,
          first_page_url: r.data.first_page_url,
          from: r.data.from,
          last_page: r.data.last_page,
          last_page_url: r.data.last_page_url,
          next_page_url: r.data.next_page_url,
          path: r.data.path,
          per_page: r.data.per_page,
          prev_page_url: r.data.prev_page_url,
          to: r.data.to,
          total: r.data.total,
        };
      }
    });
    this.isLoading = false;
  }

  async getPartners(): Promise<void> {
    await getRequest({
      uri: `/common/partners`,
      formData: {
        organization_id: store.getters.getOrganizationMember.organization_id,
        pagination: false,
      },
      isProtected: true,
    }).then((r: ResponseIndex) => {
      if (r.data) {
        this.partners = r.data;
      }
    });
  }

  async getArticles(): Promise<void> {
    await getRequest({
      uri: `/common/articles`,
      formData: {
        organization_id: store.getters.getOrganizationMember.organization_id,
        pagination: false,
      },
      isProtected: true,
    }).then((r: ResponseIndex) => {
      if (r.data) {
        this.articles = r.data;
      }
    });
  }

  async getPlaceOfCosts(): Promise<void> {
    await getRequest({
      uri: `/common/place-of-costs`,
      formData: {
        organization_id: store.getters.getOrganizationMember.organization_id,
        pagination: false,
      },
      isProtected: true,
    }).then((r: ResponseIndex) => {
      if (r.data) {
        this.placeOfCosts = r.data;
      }
    });
  }

  async getInvoiceTypes(): Promise<void> {
    await getRequest({
      uri: `/common/invoice-types`,
      formData: {
        organization_id: store.getters.getOrganizationMember.organization_id,
        pagination: false,
      },
      isProtected: true,
    }).then((r: ResponseIndex) => {
      if (r.data) {
        this.invoiceTypes = r.data;
      }
    });
  }

  async getTransactionsUnallocatedFunds(partnerId: string, paymentTypeId: string, incomingInvoice: AppIncomingInvoiceIndex): Promise<void> {
    await getRequest({
      uri: `/common/transactions/by-partner/unallocated-funds`,
      formData: {
        organization_id: store.getters.getOrganizationMember.organization_id,
        partner_id: partnerId,
        payment_type_id: paymentTypeId,
      },
      isProtected: true,
    }).then((r: ResponseIndex) => {
      if (r.data) {
        this.transactions = r.data;
        this.incomingInvoice = incomingInvoice;

        if (this.transactions.length > 0) {
          this.setDialogIncomingInvoiceCreateIncomingInvoicePayment();
        }
      }
    });
  }

  setPagination(page): void {
    this.getIncomingInvoices(this.searchQuery, this.partner_id, this.status, this.year, page);
  }

  setDialogIncomingInvoiceLookup(data: AppIncomingInvoiceIndex) {
    this.incomingInvoice = data;
    this.$refs.AppAdministratorInvoicesIncomingInvoicesListFormLookupIndexVue.isDialogVisible = true;
  }

  setDialogIncomingInvoiceUpdate(data: AppIncomingInvoiceIndex) {
    this.incomingInvoice = data;
    this.$refs.AppAdministratorInvoicesIncomingInvoicesListFormUpdateIndexVue.isDialogVisible = true;
  }

  setDialogIncomingInvoiceRemove(data: AppIncomingInvoiceIndex) {
    this.incomingInvoice = data;
    this.$refs.AppAdministratorInvoicesIncomingInvoicesListFormRemoveIndexVue.isDialogVisible = true;
  }

  setDialogIncomingInvoiceCreateIncomingInvoicePayment() {
    this.$refs.AppAdministratorInvoicesIncomingInvoicesListFormCreateIncomingInvoicePaymentIndexVue.isDialogVisible = true;
  }

  async created(): Promise<void> {
    await this.getIncomingInvoices();
    await this.getPartners();
    await this.getArticles();
    await this.getPlaceOfCosts();
    await this.getInvoiceTypes();
  }
}
