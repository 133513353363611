
// Vue
import {Options, Vue} from "vue-class-component";

// Models
import {AppIncomingInvoiceIndex} from "@/models/app/incoming-invoice";
import {AppInvoicePaymentIndex} from "@/models/app/invoice-payment";

// Components
import {
  ElDialog,
  ElTable,
  ElTableColumn,
  ElCollapse,
  ElCollapseItem,
  ElButton,
} from "element-plus";
import {
  Right,
} from "@element-plus/icons-vue";

// Services
import {getDateFormat} from "@/services/app/date";
import {getInformation, getCurrencyFormatHRK, getCurrencyFormatEUR, getInvoiceStatus, getInvoiceStatusClass, getInvoiceNumberInYear, getInvoiceItemsStatus} from "@/services/app/data";
import {getTranslation} from "@/services/app/translation";

@Options({
  props: [
    "incomingInvoice",
  ],
  components: {
    ElDialog,
    ElButton,
    ElTable,
    ElTableColumn,
    ElCollapse,
    ElCollapseItem,
    Right,
  },
})
export default class AppAdministratorInvoicesIncomingInvoicesListFormLookupIndexVue extends Vue {
  isDialogVisible = false;

  incomingInvoice: AppIncomingInvoiceIndex = new AppIncomingInvoiceIndex();

  get translation(): any {
    return getTranslation([
      "accountNumber",
      "amount",
      "amountWithVAT",
      "amountWithoutVAT",
      "article",
      "compensation",
      "date",
      "dateOfClosing",
      "dateOfExchangeRate",
      "excerpt",
      "invoiceNumber",
      "invoiceNumberInYear",
      "invoicePayment",
      "invoiceType",
      "paidAmount",
      "partner",
      "placeOfCost",
      "paymentDescription",
      "review",
      "reviewExcerpt",
      "status",
      "transaction",
      "unitAmount",
      "unitDiscount",
      "unitPrice",
      "year",
    ]);
  }

  getIsTransactionSet(data: AppInvoicePaymentIndex): boolean {
    return data.transaction_id !== null;
  }

  getIsInvoicePaymentCompensation(data: AppInvoicePaymentIndex): boolean {
    return data.is_compensation === true;
  }

  getDateFormat(data: string): string {
    return getDateFormat(data);
  }

  getInformation(data: string): string {
    return getInformation(data);
  }

  getCurrencyFormatHRK(data: string): string {
    return getCurrencyFormatHRK(data);
  }

  getCurrencyFormatEUR(data: string): string {
    return getCurrencyFormatEUR(data);
  }

  getInvoiceStatus(data: string): string {
    return getInvoiceStatus(data);
  }

  getInvoiceStatusClass(data: string): string {
    return getInvoiceStatusClass(data);
  }

  getInvoiceNumberInYear(number: string, year: string): string {
    return getInvoiceNumberInYear(number, year);
  }

  getInvoiceItemsStatus(status: string): boolean {
    return getInvoiceItemsStatus(status);
  }
}
