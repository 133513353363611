// Models
import {AppArticleIndex} from "@/models/app/article";
import {AppPlaceOfCostIndex} from "@/models/app/place-of-cost";

export interface AppIncomingInvoiceItemIndex {
  id: string;
  organization_id: string | string[];
  incoming_invoice_id: string | string[];
  article_id: string | string[];
  place_of_cost_id: string | string[];
  content: {
    amount: {
      hrk: string,
      eur: string,
    };
    unitAmount: string;
    unitPrice: {
      hrk: string,
      eur: string,
    };
    unitDiscount: string;
  }
  created_at: string;
  updated_at: string;
  article: AppArticleIndex;
  place_of_cost: AppPlaceOfCostIndex;
}

export class AppIncomingInvoiceItemIndex implements AppIncomingInvoiceItemIndex {
  id: string;
  organization_id: string | string[];
  incoming_invoice_id: string | string[];
  article_id: string | string[];
  place_of_cost_id: string | string[];
  content: {
    amount: {
      hrk: string,
      eur: string,
    };
    unitAmount: string;
    unitPrice: {
      hrk: string,
      eur: string,
    };
    unitDiscount: string;
  }
  created_at: string;
  updated_at: string;
  article: AppArticleIndex;
  place_of_cost: AppPlaceOfCostIndex;

  constructor() {
    this.id = "";
    this.organization_id = "";
    this.incoming_invoice_id = "";
    this.article_id = "";
    this.place_of_cost_id = "";
    this.content = {
      amount: {
        hrk: "",
        eur: "",
      },
      unitAmount: "",
      unitPrice: {
        hrk: "",
        eur: "",
      },
      unitDiscount: "",
    };
    this.created_at = "";
    this.updated_at = "";
    this.article = new AppArticleIndex();
    this.place_of_cost = new AppPlaceOfCostIndex();
  }
}