
// Vue
import {Options, Vue} from "vue-class-component";

// Store
import store from "@/store";

// Models
import {AppPartnerIndex} from "@/models/app/partner";
import {AppIncomingInvoiceIndex} from "@/models/app/incoming-invoice";
import {AppIncomingInvoiceCreateIndex} from "@/models/app/incoming-invoice/create";
import {AppIncomingInvoiceItemIndex} from "@/models/app/incoming-invoice-item";
import {ResponseIndex} from "@/models/response";
import {PaginationIndex} from "@/models/pagination";

// Components
import {
  ElDialog,
  ElRow,
  ElCol,
  ElTable,
  ElTableColumn,
  ElForm,
  ElFormItem,
  ElInput,
  ElDatePicker,
  ElSelect,
  ElOption,
  ElButton,
  ElAlert,
} from "element-plus";
import {
  Plus,
} from "@element-plus/icons-vue";

// Services
import {getRequest} from "@/services/api/request";
import {postRequest} from "@/services/api/request";
import {getTranslation} from "@/services/app/translation";

@Options({
  props: [
    "partners",
    "articles",
    "placeOfCosts",
    "invoiceTypes",
    "searchQuery",
    "partnerId",
    "year",
    "status",
    "pagination",
  ],
  components: {
    ElDialog,
    ElRow,
    ElCol,
    ElTable,
    ElTableColumn,
    ElForm,
    ElFormItem,
    ElInput,
    ElDatePicker,
    ElSelect,
    ElOption,
    ElButton,
    ElAlert,
    Plus,
  },
  watch: {
    'formData.partner_id'(): void {
      if (this.formData.partner_id !== "") {
        this.partner = this.partners.find((item) => item.id === this.formData.partner_id);
        this.formData.content.accountNumber = "";
      }
    },
    'formData.content.amount.eur'(): void {
      this.formData.content.amount.hrk = Number(this.formData.content.amount.eur * 7.53450).toFixed(2);
    },
  },
})
export default class AppAdministratorInvoicesIncomingInvoicesListFormCreateIndexVue extends Vue {
  isLoading = false;
  isDialogVisible = false;
  isDisabled = false;

  partner: AppPartnerIndex = new AppPartnerIndex();
  incomingInvoiceLastInYear: AppIncomingInvoiceIndex = new AppIncomingInvoiceIndex();

  searchQuery = "*";
  partnerId = "*";
  status = "*";
  year = new Date().getFullYear();
  pagination = new PaginationIndex();

  formData: AppIncomingInvoiceCreateIndex = new AppIncomingInvoiceCreateIndex();
  formDataRules = {
    partner_id: [
      {
        required: true,
        trigger: "blur",
      },
    ],
    invoice_type_id: [
      {
        required: true,
        trigger: "blur",
      },
    ],
    content: {
      year: [
        {
          required: true,
          trigger: "blur",
        },
      ],
      invoiceNumber: [
        {
          required: true,
          trigger: "blur",
        },
      ],
      invoiceNumberInYear: [
        {
          required: true,
          trigger: "blur",
        },
      ],
      paymentDescription: [
        {
          required: true,
          trigger: "blur",
        },
      ],
      amount: {
        hrk: [
          {
            required: true,
            trigger: "blur",
          },
        ],
        eur: [
          {
            required: true,
            trigger: "blur",
          },
        ],
      },
      date: [
        {
          required: true,
          trigger: "blur",
        },
      ],
      dateOfExchangeRate: [
        {
          required: true,
          trigger: "blur",
        },
      ],
    },
  };

  get translation(): any {
    return getTranslation([
      "accountNumber",
      "add",
      "amountWithVAT",
      "amountWithoutVAT",
      "article",
      "choose",
      "date",
      "dateOfExchangeRate",
      "listOfItemsStatus",
      "invoiceNumber",
      "invoiceNumberInYear",
      "invoiceType",
      "partner",
      "placeOfCost",
      "paymentDescription",
      "titlePlaceholderAmountWithVAT",
      "titlePlaceholderAmountWithoutVAT",
      "titlePlaceholderDate",
      "titlePlaceholderDateOfExchangeRate",
      "titlePlaceholderInvoiceNumber",
      "titlePlaceholderInvoiceNumberInYear",
      "titlePlaceholderPaymentDescription",
      "titlePlaceholderUnitAmount",
      "titlePlaceholderUnitDiscount",
      "titlePlaceholderUnitPrice",
      "titlePlaceholderYear",
      "unitAmount",
      "unitDiscount",
      "unitPrice",
      "year",
    ]);
  }

  get isValidIncomingInvoiceItems(): boolean {
    let status = true;

    if (this.formData.content.listOfItemsStatus === "1") {
      if (this.formData.incoming_invoice_items.length === 0) {
        status = false;
      }

      this.formData.incoming_invoice_items.forEach(item => {
        if (item.article_id === "") {
          status = false;
        }

        if (item.place_of_cost_id === "") {
          status = false;
        }

        if (item.content.amount.eur === "") {
          status = false;
        }

        if (item.content.unitPrice.eur === "") {
          status = false;
        }

        if (item.content.unitAmount === "") {
          status = false;
        }

        if (item.content.unitDiscount === "") {
          status = false;
        }
      });
    }

    return status;
  }

  $refs!: {
    formComponent: HTMLFormElement;
  };

  validateForm(): void {
    this.$refs.formComponent.validate((response: any) => {
      if (response && this.isValidIncomingInvoiceItems) {
        this.submitForm();
      }
    });
  }

  async submitForm(): Promise<void> {
    this.isLoading = true;
    await postRequest({
      uri: "/common/incoming-invoice/create",
      formData: this.formData,
      isProtected: true,
      isSuccessNotificationVisible: true,
      isErrorNotificationVisible: true,
    }).then((r: ResponseIndex) => {
      if (r.status === "success") {
        this.$emit('getIncomingInvoices', this.searchQuery, this.partnerId, this.status, this.year, this.pagination.current_page);
        this.$emit('getTransactionsUnallocatedFunds', this.partner.id, "9570e1e2-68ea-4a3e-ba8d-10edc197f75b", r.data);
        this.isDialogVisible = false;
        this.resetFormData();
      }
    });
    this.isLoading = false;
  }

  async getIncomingInvoiceStatus(): Promise<void> {
    await getRequest({
      uri: `/common/incoming-invoice/status`,
      formData: {
        organization_id: this.formData.organization_id,
        partner_id: this.formData.partner_id,
        year: this.formData.content.year,
        invoiceNumber: this.formData.content.invoiceNumber,
        invoiceNumberInYear: this.formData.content.invoiceNumberInYear,
      },
      isProtected: true,
    }).then((r: ResponseIndex) => {
      this.isDisabled = r.data !== null;
    });
  }

  async getIncomingInvoiceLastInYear(): Promise<void> {
    await getRequest({
      uri: `/common/incoming-invoice/last-in-year`,
      formData: {
        organization_id: store.getters.getOrganizationMember.organization_id,
      },
      isProtected: true,
    }).then((r: ResponseIndex) => {
      if (r.data) {
        this.incomingInvoiceLastInYear = r.data;
        this.formData.content.invoiceNumberInYear = (parseInt(this.incomingInvoiceLastInYear.content.invoiceNumberInYear) + 1).toString();
      } else {
        this.formData.content.invoiceNumberInYear = "1";
      }
    });
  }

  async resetFormData(): Promise<void> {
    this.formData = new AppIncomingInvoiceCreateIndex();
    await this.getIncomingInvoiceLastInYear();
  }

  setIncomingInvoiceItem(): void {
    this.formData.incoming_invoice_items.push(new AppIncomingInvoiceItemIndex());
  }

  async created(): Promise<void> {
    await this.getIncomingInvoiceLastInYear();
  }
}
