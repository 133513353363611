import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-931d619a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "app-administrator-invoices-incoming-invoices-list" }
const _hoisted_2 = { class: "box" }
const _hoisted_3 = { class: "box__headline" }
const _hoisted_4 = { class: "title" }
const _hoisted_5 = { class: "action" }
const _hoisted_6 = { class: "box__filter" }
const _hoisted_7 = {
  key: 0,
  class: "box__list"
}
const _hoisted_8 = { class: "el-table-column__action" }
const _hoisted_9 = {
  key: 0,
  class: "pagination"
}
const _hoisted_10 = {
  key: 1,
  class: "box__message"
}
const _hoisted_11 = {
  key: 2,
  class: "box__loading"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppAdministratorInvoicesIncomingInvoicesListFormCreateIndexVue = _resolveComponent("AppAdministratorInvoicesIncomingInvoicesListFormCreateIndexVue")!
  const _component_AppAdministratorInvoicesIncomingInvoicesListFormFilterIndexVue = _resolveComponent("AppAdministratorInvoicesIncomingInvoicesListFormFilterIndexVue")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_Search = _resolveComponent("Search")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_Edit = _resolveComponent("Edit")!
  const _component_Delete = _resolveComponent("Delete")!
  const _component_el_table = _resolveComponent("el-table")!
  const _component_el_pagination = _resolveComponent("el-pagination")!
  const _component_LoadingIndexVue = _resolveComponent("LoadingIndexVue")!
  const _component_AppAdministratorInvoicesIncomingInvoicesListFormLookupIndexVue = _resolveComponent("AppAdministratorInvoicesIncomingInvoicesListFormLookupIndexVue")!
  const _component_AppAdministratorInvoicesIncomingInvoicesListFormUpdateIndexVue = _resolveComponent("AppAdministratorInvoicesIncomingInvoicesListFormUpdateIndexVue")!
  const _component_AppAdministratorInvoicesIncomingInvoicesListFormRemoveIndexVue = _resolveComponent("AppAdministratorInvoicesIncomingInvoicesListFormRemoveIndexVue")!
  const _component_AppAdministratorInvoicesIncomingInvoicesListFormCreateIncomingInvoicePaymentIndexVue = _resolveComponent("AppAdministratorInvoicesIncomingInvoicesListFormCreateIncomingInvoicePaymentIndexVue")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h1", _hoisted_4, _toDisplayString(_ctx.translation.incomingInvoices), 1),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_AppAdministratorInvoicesIncomingInvoicesListFormCreateIndexVue, {
            partners: _ctx.partners,
            articles: _ctx.articles,
            "place-of-costs": _ctx.placeOfCosts,
            "invoice-types": _ctx.invoiceTypes,
            "search-query": _ctx.searchQuery,
            "partner-id": _ctx.partner_id,
            status: _ctx.status,
            year: _ctx.year,
            pagination: _ctx.pagination,
            onGetIncomingInvoices: _ctx.getIncomingInvoices,
            onGetTransactionsUnallocatedFunds: _ctx.getTransactionsUnallocatedFunds
          }, null, 8, ["partners", "articles", "place-of-costs", "invoice-types", "search-query", "partner-id", "status", "year", "pagination", "onGetIncomingInvoices", "onGetTransactionsUnallocatedFunds"])
        ])
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_component_AppAdministratorInvoicesIncomingInvoicesListFormFilterIndexVue, {
          partners: _ctx.partners,
          onGetIncomingInvoices: _ctx.getIncomingInvoices
        }, null, 8, ["partners", "onGetIncomingInvoices"])
      ]),
      (_ctx.isDataLoaded)
        ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
            _createVNode(_component_el_table, {
              data: _ctx.incomingInvoices,
              style: {"width":"100%"}
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_table_column, {
                  label: _ctx.translation.invoiceNumberInYearURA,
                  width: "100"
                }, {
                  default: _withCtx((scope) => [
                    _createTextVNode(_toDisplayString(_ctx.getInvoiceNumberInYear(scope.row.content.invoiceNumberInYear, scope.row.content.year)) + " " + _toDisplayString(_ctx.getInformation(scope.row.invoice_type.content.title)), 1)
                  ]),
                  _: 1
                }, 8, ["label"]),
                _createVNode(_component_el_table_column, {
                  label: _ctx.translation.invoiceNumber,
                  width: "160"
                }, {
                  default: _withCtx((scope) => [
                    _createTextVNode(_toDisplayString(_ctx.getInformation(scope.row.content.invoiceNumber)), 1)
                  ]),
                  _: 1
                }, 8, ["label"]),
                _createVNode(_component_el_table_column, {
                  label: _ctx.translation.partner,
                  "min-width": "200"
                }, {
                  default: _withCtx((scope) => [
                    _createTextVNode(_toDisplayString(_ctx.getInformation(scope.row.partner.content.title).slice(0, 32)), 1)
                  ]),
                  _: 1
                }, 8, ["label"]),
                _createVNode(_component_el_table_column, {
                  label: _ctx.translation.paymentDescription,
                  "min-width": "220"
                }, {
                  default: _withCtx((scope) => [
                    _createTextVNode(_toDisplayString(_ctx.getInformation(scope.row.content.paymentDescription).slice(0, 32)), 1)
                  ]),
                  _: 1
                }, 8, ["label"]),
                _createVNode(_component_el_table_column, {
                  label: _ctx.translation.date,
                  width: "100"
                }, {
                  default: _withCtx((scope) => [
                    _createTextVNode(_toDisplayString(_ctx.getDateFormat(scope.row.content.date)), 1)
                  ]),
                  _: 1
                }, 8, ["label"]),
                _createVNode(_component_el_table_column, {
                  label: _ctx.translation.dateOfExchangeRateShort,
                  width: "100"
                }, {
                  default: _withCtx((scope) => [
                    _createTextVNode(_toDisplayString(_ctx.getDateFormat(scope.row.content.dateOfExchangeRate)), 1)
                  ]),
                  _: 1
                }, 8, ["label"]),
                _createVNode(_component_el_table_column, {
                  label: _ctx.translation.amountWithVAT,
                  width: "110"
                }, {
                  default: _withCtx((scope) => [
                    _createTextVNode(_toDisplayString(_ctx.getCurrencyFormatEUR(scope.row.content.amount.eur)), 1)
                  ]),
                  _: 1
                }, 8, ["label"]),
                _createVNode(_component_el_table_column, {
                  label: _ctx.translation.paid,
                  width: "110"
                }, {
                  default: _withCtx((scope) => [
                    _createTextVNode(_toDisplayString(_ctx.getCurrencyFormatEUR(scope.row.content.paidAmount.eur)), 1)
                  ]),
                  _: 1
                }, 8, ["label"]),
                _createVNode(_component_el_table_column, {
                  label: _ctx.translation.payments,
                  align: "center",
                  width: "110"
                }, {
                  default: _withCtx((scope) => [
                    _createTextVNode(_toDisplayString(scope.row.invoice_payments.length), 1)
                  ]),
                  _: 1
                }, 8, ["label"]),
                _createVNode(_component_el_table_column, {
                  label: _ctx.translation.status,
                  align: "center",
                  width: "100"
                }, {
                  default: _withCtx((scope) => [
                    _createElementVNode("span", {
                      class: _normalizeClass(`status--${_ctx.getInvoiceStatusClass(scope.row.content.status)}`)
                    }, _toDisplayString(_ctx.getInvoiceStatusShort(scope.row.content.status)), 3)
                  ]),
                  _: 1
                }, 8, ["label"]),
                _createVNode(_component_el_table_column, {
                  width: "132",
                  fixed: "right"
                }, {
                  default: _withCtx((scope) => [
                    _createElementVNode("div", _hoisted_8, [
                      _createVNode(_component_el_button, {
                        class: "el-button--grey el-button--square",
                        onClick: ($event: any) => (_ctx.setDialogIncomingInvoiceLookup(scope.row))
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_Search)
                        ]),
                        _: 2
                      }, 1032, ["onClick"]),
                      _createVNode(_component_el_button, {
                        class: "el-button--grey el-button--square",
                        onClick: ($event: any) => (_ctx.setDialogIncomingInvoiceUpdate(scope.row))
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_Edit)
                        ]),
                        _: 2
                      }, 1032, ["onClick"]),
                      _createVNode(_component_el_button, {
                        class: "el-button--grey el-button--square",
                        onClick: ($event: any) => (_ctx.setDialogIncomingInvoiceRemove(scope.row))
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_Delete)
                        ]),
                        _: 2
                      }, 1032, ["onClick"])
                    ])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["data"]),
            (_ctx.isPaginationVisible)
              ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                  _createVNode(_component_el_pagination, {
                    background: "",
                    layout: "prev, pager, next",
                    "page-size": 24,
                    total: _ctx.pagination.total,
                    "current-page": _ctx.pagination.current_page,
                    onCurrentChange: _ctx.setPagination
                  }, null, 8, ["total", "current-page", "onCurrentChange"])
                ]))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true),
      (_ctx.isDataLoadedAndEmpty)
        ? (_openBlock(), _createElementBlock("div", _hoisted_10, _toDisplayString(_ctx.translation.noData), 1))
        : _createCommentVNode("", true),
      (_ctx.isLoading)
        ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
            _createVNode(_component_LoadingIndexVue)
          ]))
        : _createCommentVNode("", true)
    ]),
    _createVNode(_component_AppAdministratorInvoicesIncomingInvoicesListFormLookupIndexVue, {
      ref: "AppAdministratorInvoicesIncomingInvoicesListFormLookupIndexVue",
      "incoming-invoice": _ctx.incomingInvoice
    }, null, 8, ["incoming-invoice"]),
    _createVNode(_component_AppAdministratorInvoicesIncomingInvoicesListFormUpdateIndexVue, {
      ref: "AppAdministratorInvoicesIncomingInvoicesListFormUpdateIndexVue",
      "incoming-invoice": _ctx.incomingInvoice,
      partners: _ctx.partners,
      articles: _ctx.articles,
      "place-of-costs": _ctx.placeOfCosts,
      "invoice-types": _ctx.invoiceTypes,
      "search-query": _ctx.searchQuery,
      "partner-id": _ctx.partner_id,
      status: _ctx.status,
      year: _ctx.year,
      pagination: _ctx.pagination,
      onGetIncomingInvoices: _ctx.getIncomingInvoices
    }, null, 8, ["incoming-invoice", "partners", "articles", "place-of-costs", "invoice-types", "search-query", "partner-id", "status", "year", "pagination", "onGetIncomingInvoices"]),
    _createVNode(_component_AppAdministratorInvoicesIncomingInvoicesListFormRemoveIndexVue, {
      ref: "AppAdministratorInvoicesIncomingInvoicesListFormRemoveIndexVue",
      "incoming-invoice": _ctx.incomingInvoice,
      "search-query": _ctx.searchQuery,
      "partner-id": _ctx.partner_id,
      status: _ctx.status,
      year: _ctx.year,
      pagination: _ctx.pagination,
      onGetIncomingInvoices: _ctx.getIncomingInvoices
    }, null, 8, ["incoming-invoice", "search-query", "partner-id", "status", "year", "pagination", "onGetIncomingInvoices"]),
    _createVNode(_component_AppAdministratorInvoicesIncomingInvoicesListFormCreateIncomingInvoicePaymentIndexVue, {
      ref: "AppAdministratorInvoicesIncomingInvoicesListFormCreateIncomingInvoicePaymentIndexVue",
      "incoming-invoice": _ctx.incomingInvoice,
      transactions: _ctx.transactions,
      "search-query": _ctx.searchQuery,
      "partner-id": _ctx.partner_id,
      status: _ctx.status,
      year: _ctx.year,
      pagination: _ctx.pagination,
      onGetIncomingInvoices: _ctx.getIncomingInvoices
    }, null, 8, ["incoming-invoice", "transactions", "search-query", "partner-id", "status", "year", "pagination", "onGetIncomingInvoices"])
  ]))
}